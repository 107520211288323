<template>
  <!-- footer -->
  <div class="footer">
    <div class="top px1200">
      <ul>
        <h2>品牌文化</h2>
        <li><my-link href="/reservice">公司简介</my-link></li>
      </ul>
      <ul>
        <h2>产品中心</h2>
        <li><my-link href="/mall">养生茶系列</my-link></li>
        <li><my-link href="/mall">功效查系列</my-link></li>
      </ul>
      <ul>
        <h2>新闻中心</h2>
        <li><a href="/news">公司新闻</a></li>
        <li><a href="/news">养生知识</a></li>
      </ul>
      <ul>
        <h2>加入我们</h2>
        <li><my-link href="/serviceProject">加入我们</my-link></li>
      </ul>
      <ul>
        <h2>联系我们</h2>
        <li><my-link href="/contactUs">联系我们</my-link></li>
      </ul>
      <div class="erweim">
        <div v-for="item in publicNo" :key="item.id">
          <img :src="httpPath + item.imgUrl" alt="">
          <p>关注微信公众号</p>
        </div>
      </div>
    </div>
    <div class="baian">
      <span style="color:#330006;">合作单位：{{ commonUtils.getDictName('web_display_config', 'sponsor') }}</span>
      <span style="color:#330006;margin-left: 20px;">{{ commonUtils.getDictName('web_display_config', 'copyright') }}</span>
      <a :href="commonUtils.getDictName('web_display_config','beian_target')" style="color:#330006;margin-left: 20px;"
         target="view_window">{{ commonUtils.getDictName('web_display_config', 'beian') }}
      </a>
    </div>
  </div>
          <!--  <footer id="footer" class="footer">-->
<!--    <div class="wrap-lg">-->
<!--      <div class="footer_content">-->
<!--        <div class="content-left">-->
<!--           <span>-->
<!--          合作单位：{{ commonUtils.getDictName('web_display_config', 'sponsor') }}-->
<!--        </span>-->
<!--          <span>-->
<!--          {{ commonUtils.getDictName('web_display_config', 'copyright') }}-->
<!--         <span><a :href="commonUtils.getDictName('web_display_config','beian_target')"-->
<!--                  style="color:#330006;margin-left: 20px;"-->
<!--                  target="view_window">{{ commonUtils.getDictName('web_display_config', 'beian') }}</a></span>-->
<!--        </span>-->
<!--        </div>-->
<!--        <div class="content-right">-->
<!--          <a target="view_window"><img src="../../assets/img/foot/wx.png"></a>-->
<!--          <a target="view_window"><img src="../../assets/img/foot/qq.png"></a>-->
<!--          <a target="view_window"><img src="../../assets/img/foot/qqkj.png"></a>-->
<!--          <a target="view_window"><img src="../../assets/img/foot/bd.png"></a>-->
<!--          <a target="view_window"><img src="../../assets/img/foot/wb.png"></a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </footer>-->
</template>

<script>
import { getLists as adsPicList } from '@/api/ads/adsPics'

export default {
  name: 'bottomFooter',
  data: function () {
    return {
      httpPath: '',
      publicNo: [],
      url: ''
    }
  },
  mounted () {
    this.httpPath = this.commonUtils.getPara('httpPath')
    this.initData()
  },
  methods: {
    initData: function () {
      this.adsPicList()
    },
    adsPicList () {
      let param = {}
      param.categoryCode = 'publicno'
      adsPicList(param).then(res => {
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          this.publicNo = result.bizData
        }
      }).catch(ex => {
        this.bannerLoading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  color: #330006;
  font-size: 14px;
  padding: 10px 0 10px 0;
  //min-width: 1200px;

  .footer_content {
    width: 100%;
    display: inline-flex;
    line-height: 50px;

    .content-left {
      width: 70%;
    }

    .content-right {
      width: 30%;

      img {
        vertical-align: middle;
        margin-left: 15px;
      }
    }

    span {
      margin-right: 10px;
    }
  }
}
</style>
