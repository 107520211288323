<template>
  <div class="sgs-floating-menu">
    <el-backtop class="toTop" style="right: 4px;bottom: 75px;padding: 5px; width: 60px;border-radius: 5px;box-shadow:none;
      height: 64px;">
      <div style="height: 100%;width: 100%;text-align: center;color:#c19954;">
        <i class="iconfont icon-huidaodingbu1" style="font-size: 36px;"></i><div style="color: #303133;font-size: 12px">回到顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import { toDoCount } from '@/api/user/my'
export default {
  name: 'floatingMenu',
  data: function () {
    return {
      toDoCount: {
        waitTestCount: 0,
        testingCount: 0,
        waitPayCount: 0,
        reportCount: 0
      },
      qqUrl: 'tencent://Message/?Uin=' + this.commonUtils.getDictName('web_display_config', 'qq') + '&websiteName=' +
      'http://www.darihua.com&Menu=yes'
    }
  },
  mounted () {
    if (this.isLogin) {
      this.getToDoCount()
    }
  },
  computed: {
    isLogin () {
      return this.$store.state.user.isLogin
    }
  },
  methods: {
    getToDoCount () {
      toDoCount(this.userInfo).then(res => {
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          this.toDoCount = result.bizData
        }
      }).catch(ex => {
        this.loading = false
        this.$message.error('获取我的待办，请稍后再试！')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  /* 小型屏幕(1280分辨率) */
  @media screen and (max-width: 1280px)  {
    .toTop{
      background: #ffffff;
    }
    .sgs-floating-menu {
      width: 56px;
      height: 345px;
      position: fixed;
      right: 8px;
      bottom: 150px;
      z-index: 10000;
      font-size: 14px;
      .menu {
        padding: 0px;
        margin: 0px;
      }
      .menu li {
        padding: 5px;
        width: 50px;
        height: 56px;
        margin-bottom: 5px;
        cursor: pointer;
        color: #303133;
        font-size: 12px;
        text-align: center;
        position: relative;
        background: #ffffff;
        list-style: none;
        border-radius: 8px;
      }
      .tips {
        text-align: center;
        a {
          text-decoration: none;
          color: #303133;
          font-size: 12px;
        }
        i {
          font-size: 36px;
          color: #c19954;
        }
      }
    }
  }
    @media screen and (min-width: 1281px) {
      .toTop{
        background: #E4E7ED;
      }
      .sgs-floating-menu {
        width: 56px;
        height: 345px;
        position: fixed;
        right: 8px;
        bottom: 150px;
        z-index: 10000;
        font-size: 14px;
        .menu {
          padding: 0px;
          margin: 0px;
        }
        .menu li {
          padding: 5px;
          width: 50px;
          height: 56px;
          margin-bottom: 5px;
          cursor: pointer;
          color: #303133;
          font-size: 12px;
          text-align: center;
          position: relative;
          background: #E4E7ED;
          list-style: none;
          border-radius: 8px;
        }
        .tips {
          text-align: center;
          a {
            text-decoration: none;
            color: #303133;
            font-size: 12px;
          }
          i {
            font-size: 36px;
            color: #c19954;
          }
        }
      }
    }
  .entrust-popover{
    ul{
      margin: 5px 0;
      padding: 0;
      li{
        list-style: none;
        cursor: pointer;
        font-size: 16px;
        padding: 5px;
        margin-left: 15px;
        a{
          text-decoration: none;
          color: #c19954;
          &:hover {
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .to-do-list{
    color: #c19954;
    display: inline-flex;
    a{
      text-decoration: none;
      color: #303133;
      width: 80px;
      height: 80px;
      margin: 5px;
      text-align: center;
      display: block;
    }
    i{
      color: #c19954;
      font-size: 30px;
      width: 30px;
      height: 40px;
    }
  }
</style>
