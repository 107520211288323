import axios from '@/api/api-request'

const projectUrl = '/my'

/**
 * 我的报告/企标/委托/订单
 */
export const myCount = () => {
  return axios.request({
    url: projectUrl + '/myCount.do',
    method: 'post'
  })
}
/**
 * 我的待办 待付款/待检测/检测中/报告
 */
export const toDoCount = () => {
  return axios.request({
    url: projectUrl + '/toDoCount.do',
    method: 'post'
  })
}
