<template>
  <div class="layout-content">
    <top-banner></top-banner>
    <div>
      <router-view></router-view>
    </div>
<!--    <div style="height: 80px"></div>-->
<!--    <help></help>-->
<!--    <home-ads></home-ads>-->
    <bottom-footer></bottom-footer>
    <floating-menu></floating-menu>
  </div>
</template>

<script>

import bottomFooter from '../mian-page/bottomFooter'
import FloatingMenu from '../mian-page/floatingMenu'
// import Help from '../mian-page/help'
import TopBanner from './topBanner'
// import HomeAds from './homeAds'

export default {
  name: 'index',
  components: { TopBanner, FloatingMenu, bottomFooter }
}
</script>

<style lang="scss" scoped>
.layout-content {
  width: 100%;
  margin-top: 188px;
  //background-color: #c19954;
  //background-image: url("../../../src/assets/img/bg.svg");
  background-size: 100% 100%;
  //min-width: 1226px;
  //padding: 10px 0 10px;
  box-sizing: border-box;
  line-height: 1;
}

.img-auth {
  text-align: center;
  height: 100px;
  background-color: #fff;
  padding: 20px;

  img {
    height: 100%;
    margin-right: 80px;
  }
}
</style>
