<template>
  <div>
    <!--头部  -->
    <div class="navbar navbar-inverse navbar-fixed-top app_top_b ">
      <div class="container">
        <div class="navbar-header">
<!--          <button type="button" class="navbar-toggle collapsed">-->
<!--            <span class="sr-only">Toggle navigation</span>-->
<!--            <span class="icon-bar"></span>-->
<!--            <span class="icon-bar"></span>-->
<!--            <span class="icon-bar"></span>-->
<!--          </button>-->
          <el-button type="button"  class="navbar-toggle collapsed" @click="collapsed(collapseType)">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </el-button>

          <a class="navbar-brand" href="/home-layout"><img :src="httpPath + logoUrl" alt=""></a>
        </div>
        <div id="navbar" :class="(collapseType === 0)? 'collapse navbar-collapse' : 'navbar-collapse collapse in'">
          <ul class="nav navbar-nav">
            <li @click="swichBg(1)" :class="(currentindex === 1)? 'active' : ''"><my-link href="/home-layout">网站首页</my-link></li>
            <li @click="swichBg(2)" :class="(currentindex === 2)? 'active' : ''"><my-link href="/reorgs">茶园基地</my-link></li>
            <li @click="swichBg(3)" :class="(currentindex === 3)? 'active' : ''"><my-link href="/reservice">关于我们</my-link></li>
            <li @click="swichBg(4)" :class="(currentindex === 4)? 'active' : ''"><my-link href="/mall">产品中心</my-link></li>
            <li @click="swichBg(5)" :class="(currentindex === 5)? 'active' : ''"><my-link href="/serviceProject">招商加盟</my-link></li>
            <li @click="swichBg(6)" :class="(currentindex === 6)? 'active' : ''"><my-link href="/topic">品牌荣誉</my-link></li>
            <li @click="swichBg(7)" :class="(currentindex === 7)? 'active' : ''"><my-link href="/news">新闻资讯</my-link></li>
            <li @click="swichBg(8)" :class="(currentindex === 8)? 'active' : ''"><my-link href="/contactUs">联系我们</my-link></li>
<!--            <li @click="swichBg(9)" :class="(currentindex === 9)? 'active' : ''"><my-link href="/solution">培训资料</my-link></li>-->
          </ul>
        </div><!--/.nav-collapse -->
      </div>
    </div>
    <div class="knag_top"></div>
<!--    <div class="top-nav-backgroud">-->
<!--      <div class="wrap-lg">-->
<!--        <div class="side-content" style="height: 100%;text-align: center;">-->
<!--          <div class="side-left">-->
<!--            <div class="logo" @click="toPage('home-layout')">-->
<!--              <img src="@/assets/img/logo.png"/>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="side-center">-->
<!--            <div class="topNav">-->
<!--              <ul>-->
<!--                <li @click="swichBg(1)" :class="(currentindex === 1)? 'active-bg' : ''">-->
<!--                  <my-link href="/home-layout">网站首页</my-link>-->
<!--                </li>-->
<!--                <li @click="swichBg(2)" :class="(currentindex === 2)? 'active-bg' : ''">-->
<!--                  <my-link  href="/reorgs" >茶园基地</my-link>-->
<!--                </li>-->
<!--                <li @click="swichBg(3)" :class="(currentindex === 3)? 'active-bg' : ''">-->
<!--                  <my-link href="/reservice">关于我们</my-link>-->
<!--                </li>-->
<!--                <li @click="swichBg(4)" :class="(currentindex === 4)? 'active-bg' : ''">-->
<!--                  <my-link href="/mall">产品中心</my-link>-->
<!--                </li>-->
<!--                <li @click="swichBg(5)" :class="(currentindex === 5)? 'active-bg' : ''">-->
<!--                  <my-link href="/serviceProject">招商加盟</my-link>-->
<!--                </li>-->
<!--                <li @click="swichBg(6)" :class="(currentindex === 6)? 'active-bg' : ''">-->
<!--                  <my-link href="/topic">品牌荣誉</my-link>-->
<!--                </li >-->
<!--                <li @click="swichBg(7)" :class="(currentindex === 7)? 'active-bg' : ''">-->
<!--                  <my-link href="/news">新闻资讯</my-link>-->
<!--                </li>-->
<!--                <li @click="swichBg(8)" :class="(currentindex === 8)? 'active-bg' : ''">-->
<!--                  <my-link href="/contactUs">联系我们</my-link>-->
<!--                </li>-->
<!--                <li @click="swichBg(9)" :class="(currentindex === 9)? 'active-bg' : ''">-->
<!--                  <my-link href="/solution">培训资料</my-link>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>

export default {
  name: 'topBanner',
  data: function () {
    return {
      currentindex: 1,
      collapseType: 0,
      httpPath: '',
      logoUrl: ''
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  watch: {
    $route (to, from) {
      // eslint-disable-next-line no-unused-vars
      let path1 = to.path
      this.routeLocation(path1)
    }
  },
  mounted () {
    // eslint-disable-next-line no-unused-vars
    let path = this.$route.path
    this.httpPath = this.commonUtils.getPara('httpPath')
    this.logoUrl = this.commonUtils.getPara('logoUrl')
    this.routeLocation(path)
  },
  methods: {
    toPage (path) {
      this.$router.push({
        name: path
      })
      this.currentindex = 1
    },
    swichBg (index) {
      this.currentindex = index
      // this.$router.push({
      //   name: path
      // })
    },
    routeLocation (path) {
      if (path === '/home-layout') {
        this.currentindex = 1
      } else if (path === '/reorgs') {
        this.currentindex = 2
      } else if (path === '/reservice') {
        this.currentindex = 3
      } else if (path === '/mall' || path === '/goods/detail') {
        this.currentindex = 4
      } else if (path === '/serviceProject') {
        this.currentindex = 5
      } else if (path === '/topic' || path === '/topic/detail') {
        this.currentindex = 6
      } else if (path === '/news' || path === '/news/detail') {
        this.currentindex = 7
      } else if (path === '/contactUs') {
        this.currentindex = 8
      } else if (path === '/solution') {
        this.currentindex = 9
      }
    },
    collapsed (type) {
      if (type === 0) {
        this.collapseType = 1
      } else {
        this.collapseType = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .top-nav-backgroud {
    height: 100px;
    width: 100%;
    //background: #c19954;
    background: #f4d070;
    position: fixed;
    z-index: 99;
    top: 0;
  }
   .app_top_b {
     position: fixed;
     z-index: 99;
     top: 0;
   }
  .logo {
    padding-top: 20px;
    text-align: right;
    img {
      width: auto;
      height: 77px;
      vertical-align: middle;
    }
  }
  //.icon{
  //  display: inline-block;
  //  width: 180px;
  //  height: 180px;
  //  background: url('@/assets/img/logo2.png') no-repeat center cover;
  //  overflow: hidden;
  //}.icon:after{content: '';display: block;height: 100%;transform: translateX(-100%);background: inherit;filter: drop-shadow(144px 0 0 #fff); //需要修改的颜色值
  //  }
  .topNav {
    padding-top: 40px;
    ul {
      margin: 0;
      padding: 0;
    }
    ul li {
      font-weight: 400;
      display: block;
      float: left;
      list-style: none;
      cursor: pointer;
      .el-dropdown-link, a {
        padding: 0 18px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        //color: #461214;
        line-height: 35px;
        text-decoration: none;
        &:hover {
          color: #F6B95E;
        }
      }
    }
  }
  .side-content {
    width: 100%;
    display: inline-flex;
    .side-left {
      width: 240px;
      height: 100%;
    }
    .side-center {
      width: 100%;
      height: 100%;
    }
  }
  .active-bg {
    //height: 44px;
    border: none;
    background-image: url("../../assets/img/home/u135.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 100%;
    color: #F6B95E;
  }
</style>
